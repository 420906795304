<template>
  <b-card-code>
    <validation-observer ref="simpleRules">
      <b-form @submit.prevent="submitForm">
        <b-row>
          <b-col md="4">
            <b-form-group>
              <validation-provider
                #default="{ errors }"
                rules="required"
                name="voucher No"
              >
                <label>Voucher No.</label>
                <label style="color: red !important">*</label>
                <b-form-input
                  type="number"
                  v-model="voucherno"
                  placeholder="Enter Voucher No."
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group>
              <label>Date </label>
              <flat-pickr
                v-model="date"
                class="form-control"
                :config="{
                  dateFormat: 'd/m/Y',
                  defaultDate: new Date(),
                }"
                style="background-color: transparent"
              />
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group>
              <label>Cheque No</label>
              <label style="color: red !important">*</label>
              <validation-provider
                #default="{ errors }"
                rules="required"
                name="Cheque No"
              >
                <b-form-input
                  type="number"
                  v-model="chequeno"
                  placeholder="Enter Cheque No"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group>
              <label>Client Name</label>
              <label style="color: red !important">*</label>
              <validation-provider
                #default="{ errors }"
                rules="required"
                name="Client Name"
              >
                <b-form-input
                  v-model="client"
                  placeholder="Enter Client Name"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group>
              <label>Company Name</label>
              <label style="color: red !important">*</label>
              <validation-provider
                #default="{ errors }"
                rules="required"
                name="Company Name"
              >
                <v-select
                  v-model="company"
                  placeholder="None"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  label="name"
                  :options="companyOption"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group>
              <label>Bank Name</label>
              <label style="color: red !important">*</label>
              <validation-provider
                #default="{ errors }"
                rules="required"
                name="Bank Name"
              >
                <b-form-input
                  v-model="bankname"
                  placeholder="Enter Bank Name"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group>
              <label>Amount</label>
              <label style="color: red !important">*</label>
              <validation-provider
                #default="{ errors }"
                rules="required|integer"
                name="Amount"
              >
                <b-form-input v-model="amount" placeholder="Enter Amount" />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col md="4">
            <b-form-group>
              <label>Cheque Date</label>
              <label style="color: red !important">*</label>
              <validation-provider
                #default="{ errors }"
                rules="required"
                name="Cheque Date"
              >
                <flat-pickr
                  v-model="chequedate"
                  class="form-control"
                  :config="{
                    dateFormat: 'd/m/Y',
                    defaultDate: new Date(),
                    maxDate: new Date(),
                  }"
                  style="background-color: transparent"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group>
              <label>Reason</label>
              <label style="color: red !important">*</label>
              <validation-provider
                #default="{ errors }"
                rules="required"
                name="Reason"
              >
                <b-form-input v-model="reason" placeholder="Enter Reason" />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group>
              <label>Client's Bank</label>
              <label style="color: red !important">*</label>
              <validation-provider
                #default="{ errors }"
                rules="required"
                name="Client's Bank"
              >
                <b-form-input
                  v-model="clientbank"
                  placeholder="Enter Client's Bank"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <b-button
              variant="primary"
              type="submit"
              @click.prevent="submitForm"
              :disabled="submitDisabled"
            >
              Submit
            </b-button>
            <b-button class="ml-1" variant="primary" @click="onClickBack"
              >Back
            </b-button>
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>
  </b-card-code>
</template>
<script>
import { ValidationProvider, ValidationObserver } from "vee-validate";
import BCardCode from "@core/components/b-card-code";
import flatPickr from "vue-flatpickr-component";
import PinchScrollZoom from "@coddicat/vue-pinch-scroll-zoom";
import {
  BFormInput,
  BFormFile,
  BFormGroup,
  BForm,
  BRow,
  BCol,
  BButton,
  BFormTextarea,
  BFormDatepicker,
  BImgLazy,
  BTable,
} from "bootstrap-vue";
import vSelect from "vue-select";
import Datepicker from "vuejs-datepicker";
import axios from '@/components/axios';

export default {
  components: {
    flatPickr,
    PinchScrollZoom,
    Datepicker,
    BFormFile,
    BCardCode,
    ValidationProvider,
    ValidationObserver,
    BFormInput,
    BFormGroup,
    BForm,
    BRow,
    BCol,
    BButton,
    vSelect,
    BFormDatepicker,
    BFormTextarea,
    BImgLazy,
    BTable,
  },
  data() {
    return {
      voucherno: "",
      date: null,
      chequeno: "",
      client: "",
      company: "",
      bankname: "",
      amount: "",
      chequedate: null,
      reason: "",
      clientbank: "",
      accessToken: localStorage.getItem("accessToken"),
      baseApi: process.env.VUE_APP_APIENDPOINT,
      companyOption: [],
      id: "",
      ifEdit: false,
      submitDisabled: false,
    };
  },
  mounted() {
    const userData = JSON.parse(localStorage.getItem("UserRights"));
    const role = JSON.parse(localStorage.getItem("userData")).role;
    if (role !== "admin") {
      userData.map((item) => {
        if (item.modulename == "Cheque Return") {
          if (this.$route.params.id) {
            if (item.edit !== 1) {
              this.$router.push("/accounting/chequereturn");
            }
          } else if (item.add !== 1) {
            this.$router.push("/accounting/chequereturn");
          }
        }
      });
    }
    this.init();
    this.ifEdit = !!this.$route.params.id;
    this.ifEdit && this.getEditValue();
  },
  methods: {
    async getEditValue() {
      await axios({
        method: "GET",
        url: `${this.baseApi}/getchequeById/${this.$route.params.id}`,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${this.accessToken}`,
        },
      })
        .then((json) => {
          this.getEditItems(json.data.data);
        })
        .catch((error) => console.log(error, "error"));
    },
    getEditItems(item) {
      item.map((item) => {
        this.voucherno = item.voucherno;
        this.date = item.date;
        this.chequeno = item.chequeno;
        this.client = item.client;
        this.company = this.getCompany(item.company);
        this.bankname = item.bank;
        this.amount = item.amount;
        this.chequedate = item.chequedate;
        this.reason = item.reason;
        this.clientbank = item.clientbank;
      });
    },
    submitForm(e) {
      this.$refs.simpleRules.validate().then(async (success) => {
        let data;
        if (success) {
          data = {
            voucherno: this.voucherno,
            date: this.date,
            chequeno: this.chequeno,
            client: this.client,
            company: this.company.id,
            bank: this.bankname,
            amount: this.amount,
            chequedate: this.chequedate,
            reason: this.reason,
            clientbank: this.clientbank,
          };
        }
        // var ifEdit = this.$route.params.id ? true : false;
        if (success == false) {
          this.$swal({
            title: "Warning",
            timer: 5000,
            text: "Plese Fill the Required Field",
            icon: "warning",
            customClass: {
              confirmButton: "btn btn-primary",
            },
            buttonsStyling: false,
          });
        }
        if (success) {
          this.submitDisabled = true;

          const accessToken = localStorage.getItem("accessToken");
          const baseApi = process.env.VUE_APP_APIENDPOINT;
          await axios({
            method: `${this.ifEdit ? "put" : "post"}`,
            url: this.ifEdit
              ? `${baseApi}/cheque/${this.$route.params.id}`
              : `${baseApi}/cheque`,
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${accessToken}`,
            },
            data: JSON.stringify(data),
          })
            .then((json) => {
              this.submitDisabled = false;

              if (json.data.status == 200 || json.data) {
                this.$swal({
                  title: "Submited",
                  text: json.data.message
                    ? `${json.data.message}`
                    : "Update SuccessFully",
                  icon: "success",
                  timer: 5000,
                  customClass: {
                    confirmButton: "btn btn-primary",
                  },
                  buttonsStyling: false,
                });
              }
              this.$router.push("/accounting/chequereturn");
            })
            .catch((error) => {
              this.submitDisabled = false;

              this.$swal({
                title: "Error!",
                text: `${error}`,
                icon: "error",
                timer: 5000,
                customClass: {
                  confirmButton: "btn btn-primary",
                },
                buttonsStyling: false,
              });
            });
        }
      });
    },
    onClickBack() {
      this.$router.push("/accounting/chequereturn");
    },
    async init() {
      this.getCompany();
    },
    async getCompany(id) {
      if (id !== undefined) {
        this.id = id;
      }
      const requestOptions = {
        method: "GET",
        headers: {
          "Content-type": "application/json",
          Authorization: `Bearer ${this.accessToken}`,
        },
        url: `${this.baseApi}/company`,
      };
      await axios(requestOptions)
        .then((response) => {
          this.companyOption = response.data.data.data;
          this.companyOption.map((item) => {
            if (item.id == this.id) {
              this.company = item.name;
            }
          });
        })
        .catch((error) => console.log(error, "error"));
    },
  },
};
</script>
